import { FaqContent } from '@homebox/client';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import ButtonLink from '@/ui/components/button/ButtonLink';
import Icon from '@/ui/components/icon';
import Typography from '@/ui/components/typography';
import OutputWysiwig from '@/ui/components/wysiwyg/output';
import useTranslation from '@/utils/hooks/useTranslation';

interface FaqProps {
    faqDetails: FaqContent[];
}

const FaqItem: FC<{ faq: FaqContent }> = ({ faq }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div
            className="flex flex-col cursor-pointer items-start"
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
            itemScope
        >
            <button
                className="flex flex-row items-center w-full lg:py-6 py-4 gap-x-2"
                onClick={() => setExpanded(!expanded)}
                type="button"
            >
                <Typography
                    as="h2"
                    className="flex-auto text-left"
                    itemProp="name"
                    variant="h5"
                >{faq.title}
                </Typography>
                <Icon
                    className={classNames({ '-scale-y-100': expanded }, 'transition-[transform] duration-200')}
                    name="icon-arrow-down"
                    size={24}
                />
            </button>
            <div
                className={`transition-[max-height] duration-700 ease-in-out overflow-hidden
                ${expanded ? 'h-auto pb-6' : 'h-0'}`}
                itemProp="acceptedAnswer"
                itemType="https://schema.org/Answer"
                itemScope
            >
                <div className="prose" itemProp="text">
                    <OutputWysiwig data={faq.description} />
                </div>
            </div>
        </div>
    );
};

const FaqComponent: FC<FaqProps> = ({ faqDetails }) => (faqDetails.length === 0 ? null : (
    <div
        className="divide-y divide-grey-stroke"
        itemType="https://schema.org/FAQPage"
        itemScope
    >
        {faqDetails.map((faqDetail: FaqContent) => (
            <FaqItem
                key={faqDetail.id}
                faq={faqDetail}
            />
        ))}
    </div>
));

const FaqButton: FC = () => {
    const t = useTranslation();

    return (
        <ButtonLink
            className="mt-6"
            color="gray"
            href='/faq'
            label={t('see_all_faqs')}
            size="medium"
        />
    );
};

export default FaqComponent;
export { FaqButton };
