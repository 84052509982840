import { Homepage, Center } from '@homebox/client';
import Link from 'next/link';
import React, { FC, useRef } from 'react';
import Slider from 'react-slick';
import styles from './style.module.css';
import Button, { ButtonSizes } from '@/ui/components/button';
import FindBoxButton from '@/ui/components/button/find-a-box';
import StrapiImage from '@/ui/components/image/StrapiImage';
import WrappedSection from '@/ui/components/layout/wrappedSection';
import Typography from '@/ui/components/typography';
import { getName } from '@/utils/center';
import useAppData from '@/utils/hooks/useAppData';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';

type Section6Props = {
    centers?: Center[],
}

const Section6: FC<Section6Props> = ({ centers }) => {
    const { page, setOpenedPoppin } = useAppData<Homepage>();
    const sliderRef = useRef<Slider>(null);
    const translatedRoute = useRouteTranslator();
    const { isLight } = useWebsiteConfig();

    const handlePagingOver = (index: number) => {
        if (sliderRef && sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
    };

    const sliderSettings = {
        dots: true,
        arrows: false,
        fade: true,
        autoPlay: false,
        vertical: true,
        speed: 0,
        verticalSwiping: true,
        className: styles.slider,
        dotsClass: styles.sliderPagination,
        pauseOnDotsHover: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customPaging(index: number) {
            return (
                <button onMouseEnter={() => handlePagingOver(index)} type="button">
                    <Typography
                        as="span"
                        className="lg:!text-[24px]"
                        variant="h5"
                    >{page.slider_2.slider_content[index].title}
                    </Typography>
                </button>
            );
        },
    };

    return (
        <WrappedSection
            className="bg-egg-sour text-center"
            header={(
                <>
                    <Typography as="h2" variant="h2">{page.slider_2.title}</Typography>
                    <Typography
                        as="p"
                        className="mt-2 lg-down:hidden"
                        variant="normal"
                    >{page.slider_2.subtitle}
                    </Typography>
                </>
            )}
        >
            <div className="leading-[0px] mb-6 lg-down:max-w-[682px] mx-auto">
                <Slider {...sliderSettings} ref={sliderRef}>
                    {page.slider_2.slider_content.map((slide, index: number) => (
                        <StrapiImage
                            key={slide.image.name}
                            alt={page.slider_2.slider_content[index].title}
                            image={slide.image}
                        />
                    ))}
                </Slider>
            </div>
            <div className="flex justify-center gap-4 md-down:flex-col">
                {(isLight && centers) && centers.map((center) => (
                    <Link href={translatedRoute('LIST_SELFSTORAGE', { slug: center.slug })}>
                        <a>
                            <Button sizeVariant="big">
                                {getName(center)}
                            </Button>
                        </a>
                    </Link>
                ))}
            </div>
            {!isLight
            && (<FindBoxButton onClick={() => setOpenedPoppin('search-box')} sizeVariant={ButtonSizes.MEDIUM} />)}

        </WrappedSection>
    );
};

export default Section6;
