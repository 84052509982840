import { Homepage } from '@homebox/client';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import styles from './style.module.css';
import VirtualVisitButton from '@/ui/components/button/virtualVisit';
import StrapiImage from '@/ui/components/image/StrapiImage';
import WrappedSection from '@/ui/components/layout/wrappedSection';
import Lightbox from '@/ui/components/modal/lightbox';
import SliderArrow from '@/ui/components/slider/arrow';
import SliderProgress from '@/ui/components/slider/progress';
import Typography from '@/ui/components/typography';
import VirtualVisit from '@/ui/components/virtual-visit';
import useAppData from '@/utils/hooks/useAppData';
import useSliderProgress from '@/utils/hooks/useSliderProgress';

const Section3 = () => {
    const { page } = useAppData<Homepage>();
    const [virtualVisitOpened, setVirtualVisitOpened] = useState<boolean>(false);
    const slideNumber = page.slider.slider_content.length;
    const sliderSpeed = 500;
    const sliderAutoPlaySpeed = 15000;

    const [slide, setSlide] = useState<number>(0);
    const ref = useRef<any>(null);

    const {
        sliderTime,
        playAnimationFrame,
        pauseAnimationFrame,
    } = useSliderProgress(slide, slideNumber, sliderAutoPlaySpeed);

    const removeSiblingsClasses = () => {
        for (const child of Array.from<HTMLElement>(ref?.current.innerSlider.track.node.children)) {
            child.classList.remove('siblings');
        }
    };

    const addSiblingsClassesToSlide = (index: number) => {
        ref?.current.innerSlider.track.node.querySelector(`[data-index="${index}"]`)?.classList.add('siblings');
    };

    const sliderSettings = {
        dots: false,
        speed: sliderSpeed,
        autoplay: true,

        autoplaySpeed: sliderAutoPlaySpeed,
        infinite: false,
        className: styles.slider,
        prevArrow: <SliderArrow className="slick-prev" iconName="icon-arrow-left" />,
        nextArrow: <SliderArrow className="slick-next" iconName="icon-arrow-right" />,
        // @ts-ignore
        beforeChange(currentSlide: number, nextSlide: number) {
            setSlide(nextSlide);
        },
    };
    useEffect(() => {
        if (slide === slideNumber - 1 && sliderTime >= sliderAutoPlaySpeed) {
            ref.current.slickGoTo(0);
            removeSiblingsClasses();
        }
    }, [slide, sliderTime]);

    useEffect(() => {
        addSiblingsClassesToSlide(1);
    }, []);

    useEffect(() => {
        removeSiblingsClasses();
        setTimeout(() => {
            addSiblingsClassesToSlide(slide - 1);
            addSiblingsClassesToSlide(slide + 1);
        });
    }, [slide]);

    return (
        <>
            <WrappedSection
                className="overflow-hidden"
                header={(
                    <>
                        <Typography as="h2" variant="h2">{page.slider.title}</Typography>
                        <Typography
                            as="p"
                            className="text-dark-grey mb-6"
                            variant="normal"
                        >{page.slider.subtitle}
                        </Typography>
                        <VirtualVisitButton onClick={() => setVirtualVisitOpened(true)} />
                    </>
                )}
            >
                <div className="relative">
                    <Slider {...sliderSettings} ref={ref}>
                        {page.slider.slider_content.map((slideItem, key) => (
                            <div
                                key={slideItem.title.toString()}
                                className={styles.slide}
                                onMouseEnter={pauseAnimationFrame}
                                onMouseLeave={playAnimationFrame}
                            >
                                <StrapiImage
                                    alt={slideItem.title}
                                    image={slideItem.image}
                                />
                                <div className={styles.slideBody}>
                                    <Typography
                                        as="p"
                                        className={styles.slideTitle}
                                        data-increment={key + 1}
                                        variant="h5"
                                    >{slideItem.title}
                                    </Typography>
                                    {slideItem.description && (
                                        <Typography
                                            as="p"
                                            className="text-dark-grey mt-4"
                                            variant="normal"
                                        >{slideItem.description}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        ))}
                    </Slider>
                    <SliderProgress
                        progressClassName={styles.sliderProgress}
                        sliderAutoPlaySpeed={sliderAutoPlaySpeed}
                        sliderTime={sliderTime}
                    />
                </div>
            </WrappedSection>
            {virtualVisitOpened && (
                <Lightbox
                    isOpen={virtualVisitOpened}
                    onRequestClose={() => setVirtualVisitOpened(false)}
                    size="big"
                >
                    <VirtualVisit />
                </Lightbox>
            )}
        </>
    );
};

export default Section3;
