import classNames from 'classnames';
import Link from 'next/link';
import { FC } from 'react';
import styles from './button.module.css';
import { getColor, getSize } from '@/ui/components/button';

type ValuesOf<T extends Record<string, unknown>> = T[keyof T]

const BUTTON_SIZES = {
    DEFAULT: 'default',
    MEDIUM: 'medium',
    BIG: 'big',
} as const;

type ButtonSizes = ValuesOf<typeof BUTTON_SIZES>;

const BUTTON_COLORS = {
    DEFAULT: 'default',
    GRAY: 'gray',
    BLACK: 'black',
    BLUE: 'blue',
    GREEN: 'green',
    APRICOT: 'apricot-peach',
    PROVINCIAL: 'provincial-pink',
    GHOST: 'ghost',
} as const;

type ButtonColors = ValuesOf<typeof BUTTON_COLORS>;

interface ButtonLinkProps {
    href: string;
    label: string;
    color?: ButtonColors;
    size?: ButtonSizes;
    className?: string;
}

const ButtonLink: FC<ButtonLinkProps> = ({
    href,
    label,
    color = 'default',
    size = 'default',
    className = null,
}) => {
    const buttonClassNames = classNames(
        styles.button,
        getColor(color),
        getSize(size),
        className,
    );

    return (
        <Link href={href}>
            <a className={buttonClassNames}>
                <div className={styles.buttonInner}>
                    {label}
                </div>
            </a>
        </Link>
    );
};

export default ButtonLink;
