import { Media } from '@homebox/client';
import Image, { ImageProps } from 'next/image';
import React, { FC } from 'react';

interface StrapiImageProps extends Omit<ImageProps, 'src'> {
    image: Media,
    width?: number,
    height?: number,
    layout?: 'fixed' | 'intrinsic' | 'responsive' | undefined
}

const StrapiImage: FC<StrapiImageProps> = ({
    image,
    width = image.width,
    height = image.height,
    alt = image.alternativeText,
    objectFit,
    objectPosition,
    layout,
    sizes,
    className,
}) => (
    <Image
        alt={alt}
        className={className}
        height={height}
        layout={layout}
        objectFit={objectFit}
        objectPosition={objectPosition}
        sizes={sizes}
        src={image.url}
        width={width}
    />
);

export type { StrapiImageProps };
export default StrapiImage;
