import { Center, Homepage, Media } from '@homebox/client';
import classNames from 'classnames';
import { NextPage } from 'next';
import Link from 'next/link';
import React, { FC, useMemo } from 'react';
import Slider from 'react-slick';
import BasicLayout from '@/ui/components/bodyLayout/basicLayout';
import Button, { ButtonColors, ButtonSizes } from '@/ui/components/button';
import PriceButton from '@/ui/components/button/price';
import ImageGrid from '@/ui/components/center/imageGrid';
import FaqComponent, { FaqButton } from '@/ui/components/faq';
import GridCard from '@/ui/components/gridCard';
import Section3 from '@/ui/components/homepage/section3/section3';
import Section6 from '@/ui/components/homepage/section6/section-6';
import Icon from '@/ui/components/icon';
import ResponsiveImage from '@/ui/components/image/responsive';
import Separator from '@/ui/components/layout/separator';
import WrappedSection from '@/ui/components/layout/wrappedSection';
import SimpleQuote from '@/ui/components/quote/simple';
import SliderArrow from '@/ui/components/slider/arrow';
import { CenterPromotionTag } from '@/ui/components/tag';
import Typography from '@/ui/components/typography';
import Wrapper from '@/ui/components/wrapper';
import { getActivePromotions, getAllCenterPromotions, getName } from '@/utils/center';
import { GTMTelephoneCenter } from '@/utils/gtm';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useTranslation from '@/utils/hooks/useTranslation';
import phoneCall from '@/utils/phoneCall';

export type HomePageLightProps = {
    page: Homepage,
    countryCenters: Center[],
    allCenters: Center[],
    // eslint-disable-next-line react/no-unused-prop-types
    funnelId: number | null
}
const HomepageCenterSliderItem: FC<{ center: Center }> = ({ center }) => {
    const now = useMemo(() => new Date(), []);

    const centerPromotions = getAllCenterPromotions(center);
    const promotions = getActivePromotions(now, centerPromotions);
    const translatedRoute = useRouteTranslator();

    return (
        <div className="grid md:grid-cols-[1fr_33%] gap-5">
            <ImageGrid
                images={center.images.slice(0, 3) as [Media, Media, Media]}
                onClickImage={() => null}
                promotions={promotions}
            />
            <div className="bg-white p-8 flex flex-col justify-center gap-6 text-center">
                <div>
                    <CenterPromotionTag promotions={centerPromotions} />
                </div>
                <Link href={translatedRoute('LIST_SELFSTORAGE', {
                    slug: center.slug,
                })}
                >
                    <a>
                        <Typography
                            variant="h4"
                        >{getName(center)}
                        </Typography>
                    </a>
                </Link>
                <Typography
                    as="address"
                    className="text-dark-grey not-italic"
                >{center.address}<br />{center.postal_code} {center.city}
                </Typography>
                {center.avis_verifies && (
                    <SimpleQuote
                        average={center.avis_verifies_average || 0}
                        center={center}
                        className="mt-2"
                        quotes={center.avis_verifies}
                        totalValue={center.avis_verifies_total || 0}
                    />
                )}
                {center.phone && (
                    <div className="flex flex-col">
                        <PriceButton
                            center={center}
                            gtmUIPlace="liste"
                            sizeVariant={ButtonSizes.DEFAULT}
                        />
                        <Button
                            asElement="button"
                            className="mt-2"
                            colorVariant={ButtonColors.GRAY}
                            onClick={() => {
                                GTMTelephoneCenter('liste', center);
                                phoneCall(center.phone);
                            }}
                            type="button"
                        >
                            <Icon name="icon-phone" size={14} />
                            {center.phone}
                        </Button>
                    </div>
                )}
            </div>
        </div>

    );
};
const HomepageCentersSlider: FC<{ centers: Center[] }> = ({ centers }) => {
    if (centers.length === 1) {
        return (<HomepageCenterSliderItem center={centers[0]} />);
    }
    const sliderSettings = {
        className: classNames('px-8'),
        draggable: true,
        swipe: true,
        dots: false,
        speed: 300,
        infinite: true,
        prevArrow: <SliderArrow buttonClassName="!bg-white" className="slick-prev" iconName="icon-arrow-left" />,
        nextArrow: <SliderArrow buttonClassName="!bg-white" className="slick-next" iconName="icon-arrow-right" />,
    };

    return (
        <Slider {...sliderSettings}>{centers.map((center) => (<HomepageCenterSliderItem center={center} />))}</Slider>
    );
};

const HomepageImage: FC<{ image: Media | null }> = ({ image }) => (image !== null
    ? (
        <ResponsiveImage
            alt="HOMEBOX"
            height={680}
            layout="responsive"
            src={image.url}
            width={516}
            priority
        />
    ) : null);

const Home: NextPage<HomePageLightProps> = ({ page, allCenters, countryCenters }: HomePageLightProps) => {
    const t = useTranslation();
    const translatedRoute = useRouteTranslator();

    return (
        <BasicLayout>
            <section className="px-[24px] pt-[40px] pb-[60px] lg:px-[40px] lg:pt-8 lg:pb-[104px]">
                <Wrapper className="w-full md:w-3/4 lg:w-full">
                    <div className="grid gap-y-6 lg-down:items-center lg:grid-cols-2 lg:gap-x-[84px]">
                        <div className="flex items-start flex-col justify-center">
                            <div>
                                <Typography
                                    as="h1"
                                    className="uppercase text-red !font-bold mb-4"
                                    variant="tiny"
                                >{page.bloc_1_subtitle}
                                </Typography>
                                <Typography
                                    as="h2"
                                    variant="h1"
                                >{page.bloc_1_title}
                                </Typography>
                                <div className="flex flex-wrap items-center gap-4 mt-8">
                                    {countryCenters.map((center) => (
                                        <Link href={translatedRoute('LIST_SELFSTORAGE', { slug: center.slug })}>
                                            <a>
                                                <Button sizeVariant="big">
                                                    {getName(center)}
                                                </Button>

                                            </a>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <HomepageImage image={page.bloc_1_image} />
                    </div>
                </Wrapper>
            </section>
            <WrappedSection
                className="bg-mystic"
                header={(
                    <>
                        <Typography as="h2" variant="h2">{page.bloc_2_title}</Typography>
                        <Typography
                            as="p"
                            variant="normal"
                        >
                            {t(
                                allCenters.length > 1 ? 'discover_centers' : 'discover_center',
                                { count: allCenters.length },
                            )}
                        </Typography>
                    </>
                )}
            >
                <div className="max-w-sm md:max-w-screen-lg w-full mx-auto">
                    <HomepageCentersSlider
                        centers={allCenters}
                    />
                </div>
            </WrappedSection>
            <Section3 />
            <Wrapper>
                <Separator />
            </Wrapper>
            <WrappedSection header={(
                <>
                    <Typography as="h2" variant="h2">{page.card_list.title}</Typography>
                    <Typography
                        as="p"
                        className="text-dark-grey"
                        variant="normal"
                    >
                        {page.card_list.subtitle}
                    </Typography>
                </>
            )}
            >
                <div className="text-center">
                    <GridCard cards={page.card_list.card_detail} />
                </div>
            </WrappedSection>
            <Section6 centers={countryCenters} />
            <WrappedSection header={(<Typography as="h2" variant="h2">{page.faq.title}</Typography>)}>
                <div className="text-center">
                    <div
                        className={classNames(
                            'lg:w-[785px] mx-auto',
                            'mb-6 text-left',
                            'lg-down:divide-y-[1px] lg:divide-x-[1px]',
                        )}
                    >
                        <div>
                            <FaqComponent
                                faqDetails={page.faq.content}
                            />
                        </div>
                    </div>
                    <FaqButton />
                </div>
            </WrappedSection>
        </BasicLayout>
    );
};

export default Home;
