/*
    Rewrite from https://github.com/dev-juju/EditorJS-React-Renderer/
    because the lib doesn't support SSR
 */
import dynamic from 'next/dynamic';
import React, { FC, ReactNode } from 'react';

import {
    ErrBlock,
    ErrOutputClassNames,
    ErrOutputConfig,
    ErrOutputProps,
    ErrOutputRenderers,
    ErrOutputStyle,
} from '@/utils/types/editorjs-react-renderer';

const HeaderRenderer = dynamic(() => import('@/ui/components/wysiwyg/renderers/headerRenderer'));
const ParagraphRenderer = dynamic(() => import('@/ui/components/wysiwyg/renderers/paragraphRenderer'));
const ListRenderer = dynamic(() => import('@/ui/components/wysiwyg/renderers/listRenderer'));
const QuoteRenderer = dynamic(() => import('@/ui/components/wysiwyg/renderers/quoteRenderer'));
const ImageRenderer = dynamic(() => import('@/ui/components/wysiwyg/renderers/imageRenderer'));
const TableRenderer = dynamic(() => import('@/ui/components/wysiwyg/renderers/tableRenderer'));
const DelimiterRenderer = dynamic(() => import('@/ui/components/wysiwyg/renderers/delimiterRenderer'));

const getDefaultRenderer = (key: string): ReactNode | null => {
    switch (key) {
        case 'header':
            return HeaderRenderer;
        case 'paragraph':
            return ParagraphRenderer;
        case 'list':
            return ListRenderer;
        case 'quote':
            return QuoteRenderer;
        case 'image':
            return ImageRenderer;
        case 'table':
            return TableRenderer;
        case 'delimiter':
            return DelimiterRenderer;
        default:
            return null;
    }
};

const OutputWysiwig: FC<ErrOutputProps> = ({
    data,
    style = {},
    classNames = {},
    config = {},
    renderers = {},
}) => {
    if (!data || typeof data !== 'object') {
        return null;
    }

    return (
        <>{data.blocks.map((block: ErrBlock, index: number) => {
            const key = block.type.toLowerCase();
            const Renderer = renderers[key as keyof ErrOutputRenderers] || getDefaultRenderer(key);
            const elementKey = key + index;
            if (!Renderer) {
                return null;
            }

            return (
                // @ts-ignore
                <Renderer
                    key={elementKey}
                    classNames={classNames[key as keyof ErrOutputClassNames] || {}}
                    config={config[key as keyof ErrOutputConfig] || {}}
                    data={block.data}
                    style={style[key as keyof ErrOutputStyle] || {}}
                />
            );
        })}
        </>
    );
};

export default OutputWysiwig;
