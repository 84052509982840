import { useEffect, useRef, useState } from 'react';

const useSliderProgress = (slide: number, slideNumber: number, sliderAutoPlaySpeed: number) => {
    const [sliderTime, setSliderTime] = useState<number>(0);

    const requestRef = useRef<number>(0);
    const previousTimeRef = useRef<number | null>(null);

    const animate = (time: number) => {
        if (previousTimeRef.current !== null) {
            const deltaTime = time - previousTimeRef.current;
            setSliderTime((prevCount) => Math.min((prevCount + deltaTime), sliderAutoPlaySpeed));
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    };

    const startAnimationFrame = () => {
        requestRef.current = requestAnimationFrame(animate);
    };

    const pauseAnimationFrame = () => {
        cancelAnimationFrame(requestRef.current);
        setSliderTime(0);
    };

    const playAnimationFrame = () => {
        previousTimeRef.current = null;
        startAnimationFrame();
    };

    useEffect(() => {
        setSliderTime(0);
    }, [slide]);

    useEffect(() => {
        if (slide === slideNumber - 1 && sliderTime >= sliderAutoPlaySpeed) {
            setSliderTime(0);
        }
    }, [slide, sliderTime]);

    return { sliderTime, setSliderTime, startAnimationFrame, pauseAnimationFrame, playAnimationFrame };
};

export default useSliderProgress;
