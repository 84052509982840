import classNames from 'classnames';
import Image, { ImageProps } from 'next/image';
import React, { FC } from 'react';
import styles from './style.module.css';

const ResponsiveImage: FC<ImageProps> = ({ className, ...props }) => (
    <div className={styles.unsetImg}>
        <Image
            className={classNames(styles.customImg, className)}
            {...props}
            priority
        />
    </div>
);

export default ResponsiveImage;
