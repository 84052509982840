import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './progress.module.css';

const SliderProgress: FC<{ sliderTime: number, sliderAutoPlaySpeed: number, progressClassName?: string, progressInnerClassName?: string }> = ({
    sliderTime,
    sliderAutoPlaySpeed,
    progressClassName = '',
    progressInnerClassName = '',
}) => (
    <div className={classNames(styles.sliderProgress, progressClassName)}>
        <div
            className={classNames(styles.sliderProgressInner, progressInnerClassName)}
            style={{ width: `${(sliderTime / sliderAutoPlaySpeed) * 100}%` }}
        />
    </div>
);

export default SliderProgress;
