import { CardDetail } from '@homebox/client';
import classNames from 'classnames';
import React, { FC } from 'react';
import Icon from '@/ui/components/icon';
import StrapiImage from '@/ui/components/image/StrapiImage';
import Typography from '@/ui/components/typography';
import { nl2br } from '@/utils/text';

const CardItem: FC<{ card: { label: string, description: string, image: any } }> = ({ card }) => (
    <div className="py-12 px-6 lg:px-10">
        <div className="h-[90px] flex items-center justify-center">
            <StrapiImage alt={card.label} image={card.image} />
        </div>
        <Typography
            as="h3"
            className="text-center mt-6 mb-9"
            variant="h5"
        >{card.label}
        </Typography>
        <ul className="space-y-6 text-left max-w-[292px] mx-auto">
            {card.description.split('- ').map((text: string) => {
                const processedText = text.trim();

                return (
                    processedText.length ? (
                        <li key={processedText.toString()} className="flex gap-x-2">
                            <Icon
                                className="flex-shrink-0 mt-[.2em]"
                                name="icon-check"
                                size={18}
                            />
                            <Typography
                                as="span"
                                className="text-dark-grey"
                                variant="normal"
                            >{nl2br(processedText)}
                            </Typography>
                        </li>
                    )
                        : ''
                );
            })}
        </ul>
    </div>
);

const GridCard: FC<{ cards: CardDetail[] }> = ({ cards }) => (
    <div
        className={classNames(
            'md:w-[620px] lg:w-full mx-auto',
            'lg:grid lg:grid-cols-3',
            'mb-6 border-[1px] border-grey-stroke',
            'lg-down:divide-y-[1px] lg:divide-x-[1px] divide-grey-stroke',
        )}
    >
        {cards.map((card) => (
            <CardItem key={card.label} card={card} />
        ))}
    </div>
);

export default GridCard;
