import { GetServerSidePropsResult, NextPage } from 'next';
import HomepageLight, { HomePageLightProps } from '@/ui/pages/homepage-light';
import createServerPropsWithAppData from '@/utils/createServerPropsWithAppData';

export const getServerSideProps = createServerPropsWithAppData(async ({
    appData,
    website,
}): Promise<GetServerSidePropsResult<HomePageLightProps>> => {
    const [
        page,
        allCenters,
        funnelId,
    ] = await Promise.all([
        appData.getHomepage(),
        appData.getAllCentersForLightHomepage(),
        appData.getCurrentFunnelId(),
    ]);
    const countryCenters = allCenters
        .filter((center) => center.website?.id === website.id)
        .sort((a, b) => a.name.localeCompare(b.name));

    return {
        props: {
            page,
            allCenters,
            countryCenters,
            funnelId,
        },
    };
});

const Page: NextPage<HomePageLightProps> = HomepageLight;
export default Page;
